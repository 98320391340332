@import "../../assets/mixins.scss";

section.freedom-map {

    .container {
        flex-direction: column;
        align-items: center;

        .icon {
            width: 3.4rem;

            @include d1440 {
                width: 3.2rem;
            }

            @include d1280 {
                width: 2.8rem;
            }

            img {
                width: 100%;
            }
        }

        .text {
            font-size: .18rem;
            color: #404040;
            letter-spacing: .08em;
            line-height: 1.75em;
            text-align: center;
            
            @include d1440 {
                font-size: .16rem;
            }
            
            @include d1280 {
                font-size: .12rem;
            }

        }

        .map {
            width: 100%;

            @include m {
                margin-top: 0;
            }

            svg {
                width: 100%;

                // g {
                //     transition: transform .5s ease-in-out;
                // }

                // g:hover {
                //     transform: translateY(-10px)
                // }

                g {

                    path {
                        animation-name: play;
                        animation-duration: 3s;
                        animation-direction: alternate;
                        animation-iteration-count: infinite;
                        animation-timing-function: ease-in-out;
                    }

                    &:nth-child(1) {
                        path {
                            animation-delay: 0;
                        }
                    }

                    &:nth-child(2) {
                        path {
                            animation-delay: 1.2s;
                        }
                    }

                    &:nth-child(3) {
                        path {
                            animation-delay: 1.4s;
                        }
                    }

                    &:nth-child(4) {
                        path {
                            animation-delay: 0;
                        }
                    }

                    &:nth-child(5) {
                        path {
                            animation-delay: 1.6s;
                        }
                    }

                    &:nth-child(6) {
                        path {
                            animation-delay: 0;
                        }
                    }

                    &:nth-child(7) {
                        path {
                            animation-delay: 1.8s;
                        }
                    }

                    &:nth-child(8) {
                        path {
                            animation-delay: 0;
                        }
                    }
                }
            }
            
            @keyframes play {
                from {
                    transform: translateY(-10px);
                }
                to {
                    transform: translateY(10px);
                }
            }
        }

        .bottom {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        > * + * {
            margin-top: .32rem;

            @include d1280 {
                margin-top: .24rem;
            }
        }
    }
}