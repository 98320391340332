@import "../../assets/mixins.scss";

.slider-list {

    .container {
        flex-direction: column;

        .top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {

                * {
                    font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                    font-weight: 600;
                    font-size: .32rem;
                    color: #404040;
                    letter-spacing: .08em;
                    line-height: 1.5em;
                        
                    @include d1440 {
                        font-size: .28rem;
                    }
        
                    @include d1280 {
                        font-size: .24rem;
                    }
                }
            }
        }
        
        .items {
            position: relative;
            width: 100%;
            border: 1px solid #BBBBBB;

            @include m {
                width: auto;
                margin-left: -.2rem;
                margin-right: -.2rem;
                border: none;
            }

            .swiper-slide {
                height: auto;

                &:not(.swiper-slide-active) {
                    border-left: 1px solid #BBBBBB;

                    @include m {
                        border: none;
                    }
                }

                > div {
                    height: 100%;
                }
            }

            .navigation {
                display: flex;
                position: absolute;
                width: .6rem;
                height: .6rem;
                top:50%;
                margin-top: -.3rem;
                justify-content: center;
                align-items: center;
                border: 1px solid #BBBBBB;
                border-radius: 50%;
                cursor: pointer;
            
                @include d1440 {
                    width: .5rem;
                    height: .5rem;
                    margin-top: -.25rem;
                }

                &.left {
                    left: -.9rem;
                }

                &.right {
                    right: -.9rem;
                }

                img {
                    width: .23rem;
                }
            }

            .item {
                display: flex;
                flex-direction: column;
                flex: 1;
                height: 100%;
                justify-content: space-between;

                @include m {
                    border-left: 1px solid #BBBBBB;
                    border-top: 1px solid #BBBBBB;
                    border-bottom: 1px solid #BBBBBB;
                }

                .image {
                    width: 100%;
                }

                .content {
                    padding: .48rem;

                    @include d1440 {
                        padding: .32rem;
                    }
                    
                    @include d1440 {
                        padding: .24rem;
                    }

                    .title {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .18rem;
                        font-weight: 700;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
                            
                        @include d1440 {
                            font-size: .16rem;
                        }
            
                        @include d1280 {
                            font-size: .14rem;
                        }
                    }
    
                    .text {

                        * {
                            font-size: .14rem;
                            font-weight: 500;
                            color: #404040;
                            letter-spacing: .08em;
                            line-height: 1.5em;
                            
                            @include d1280 {
                                font-size: .12rem;
                            }
                        }
                    }
                }
            }
        }

        > * + * {
            margin-top: .6rem;

            @include d1440 {
                margin-top: .5rem;
            }

            @include d1280 {
                margin-top: .4rem;
            }

            @include m {
                margin-top: .3rem;
            }
        }
    }
}