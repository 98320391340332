@mixin m {
    @media screen and (max-width: 900px) {
        @content;
    }
}

@mixin d {
    @media screen and (min-width: 901px) {
        @content;
    }
}

@mixin d1440 {
    @media screen and (max-width: 1440px) {
        @content;
    }
}

@mixin d1280 {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin bg-cover($size: cover) {
    background-size: $size;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin bg-image($url, $size: cover) {
    background-image: url($url);
    background-size: $size;
    background-position: center;
    background-repeat: no-repeat;
}

@mixin transition($property: all 0.3s ease) {
    transition: $property;
    -webkit-transition: $property;
    -ms-transition: $property;
    -moz-transition: $property;
    -o-transition: $property;
}

@mixin transform($property) {
    transform: $property;
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
}