@import "../../assets/mixins.scss";

.banner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: #FFFBEE;

    .container {

        .image {
            position: relative;
            flex: 1;
            margin-left: -1.2rem;
            margin-right: -1.2rem;
            padding-top: calc(40% + .96rem);
            border-radius: .6rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;

            video {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                object-fit: cover;
            }

            @include d1440 {
                margin-left: -1rem;
                margin-right: -1rem;
                padding-top: calc(40% + .8rem);
            }

            @include d1280 {
                margin-left: -.4rem;
                margin-right: -.4rem;
                padding-top: calc(40% + .32rem);
            }

            @include m {
                margin-left: -.2rem;
                margin-right: -.2rem;
                padding-top: calc(60% + 2.4rem);
                border-radius: 0;
            }

            .content {
                position: absolute;
                width: calc(100% - 3rem);
                max-width: 8.25rem;
                bottom: .8rem;
                left: 1.2rem;

                @include d1440 {
                    left: 1rem;
                    bottom: .6rem
                }

                @include d1280 {
                    left: .4rem;
                    bottom: .4rem;
                }

                @include m {
                    width: calc(100% - .4rem);
                    left: .2rem;
                    bottom: .2rem;
                }
        
                .icon {
                    height: .7rem;

                    @include d1440 {
                        height: .55rem;
                    }

                    @include d1280 {
                        height: .4rem;
                    }
                }

                .title {
                    
                    * {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .48rem;
                        font-weight: 500;
                        font-style: italic;
                        color: #FFFCB2;
                        letter-spacing: .08em;
                        line-height: 1.5em;

                        @include d1440 {
                            font-size: .36rem;
                        }
    
                        @include d1280 {
                            font-size: .28rem;
                        }
                    }
                }
                
                .text {

                    * {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .18rem;
                        font-weight: 500;
                        font-style: italic;
                        color: #FFFCB2;
                        letter-spacing: .08em;
                        line-height: 1.75em;
                    
                        @include d1440 {
                            font-size: .16rem;
                        }
                        
                        @include d1280 {
                            font-size: .12rem;
                        }
                    }
                }
        
                > * + .title {
                    margin-top: .12rem;
                    
                    @include d1440 {
                        margin-top: .06rem;
                    }
                }
        
                > * + .text {
                    margin-top: .24rem;
                    
                    @include d1440 {
                        margin-top: .12rem;
                    }
                }
            }
        }

    }
}