@import "../../assets/mixins.scss";

.media-with-text {

    .container {
        display: flex;
        align-items: center;

        @include m {
            flex-direction: column;
        }

        > * + * {

            @include m {
                margin-top: .24rem;
            }
        }

        .image {
            flex: 1;

            video {
                width: 100%;
            }

            img {
                width: 100%;
            }
        }

        .content {
            flex: 1;
            padding-left: 1.2rem;

            @include d1440 {
                padding-left: .6rem;
            }

            @include m {
                padding-left: 0;
            }
    
            .subtitle {

                * {
                    font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                    font-size: .2rem;
                    font-weight: 600;
                    font-style: italic;
                    color: #404040;
                    letter-spacing: .08em;
                    line-height: 1.5em;

                    @include d1440 {
                        font-size: .16rem;
                    }
    
                    @include d1280 {
                        font-size: .14rem;
                    }
                }
            }

            .title {

                * {
                    font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                    font-size: .32rem;
                    font-weight: 700;
                    color: #404040;
                    letter-spacing: .08em;
                    line-height: 1.5em;
                
                    @include d1440 {
                        font-size: .28rem;
                    }
    
                    @include d1280 {
                        font-size: .24rem;
                    }
                }
            }
            
            .text {
                
                * {
                    font-size: .18rem;
                    color: #404040;
                    letter-spacing: .08em;
                    line-height: 1.5em;
                
                    @include d1440 {
                        font-size: .16rem;
                    }
    
                    @include d1280 {
                        font-size: .14rem;
                    }
                }
            }

            .button {
                display: flex;
            }
    
            > * + * {
                margin-top: .24rem;

                @include d1440 {
                    margin-top: .12rem;
                }
            }
        }

    }
}