@import "../../assets/mixins.scss";

.header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-bottom: 1px solid #DDDDDD;

    .container {
        flex-direction: row;
        align-items: center;

        .mobile-icon {
            position: absolute;
            width: .3rem;
            height: .3rem;
            right: .2rem;
            cursor: pointer;

            span {
                position: absolute;
                width: 100%;
                height: 2px;
                top: 50%;
                margin-top: -1px;
                border-radius: 2px;
                background-color: #A26955;

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    top: -.1rem;
                    left: 0;
                    background-color: #A26955;
                }

                &:after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: -.1rem;
                    left: 0;
                    background-color: #A26955;
                }
            }
        }

        .mobile-menu {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 9;
            overflow: auto;
            background-color: #FFFBEE;
            display: none;

            &.active {
                display: block;
            }

            .close {
                position: absolute;
                top: .2rem;
                right: .2rem;

                img {
                    width: .4rem;
                }
            }

            .items {
                display: flex;
                width: 100%;
                min-height: 100%;
                padding: .6rem .2rem;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    > div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    span {
                        font-weight: 600;
                        font-size: .16rem;
                        color: #A26955;
                        letter-spacing: .08em;
                    }

                    img {
                        width: .24rem;
                    }

                    * + * {
                        margin-left: .08rem;
                    }

                     + .item {
                        margin-top: .3rem;
                    }
                }
            }
        }

        .column {
            display: flex;
            flex: 1;

            @include m {
                display:  none;
            }

            &.right {
                justify-content: flex-end;
            }
    
            .items {
                display: flex;
                flex-direction: row;
        
                .item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    > div {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    span {
                        font-weight: 600;
                        font-size: .16rem;
                        color: #A26955;
                        letter-spacing: .08em;

                        @include d1440 {
                            font-size: .14rem;
                        }

                        @include d1280 {
                            font-size: .12rem;
                        }
                    }

                    img {
                        width: .24rem;
                    }

                    * + * {
                        margin-left: .08rem;
                    }

                     + .item {
                        margin-left: .3rem;

                        @include d1280 {
                            margin-left: .2rem;
                        }
                    }
                }
            }
        }
    
        .logo {
            padding-top: .12rem;
            padding-bottom: .12rem;

            @include d1440 {
                padding-top: .08rem;
                padding-bottom: .08rem;
            }

            @include d1280 {
                padding-top: .04rem;
                padding-bottom: .04rem;
            }

            @include m {
                padding-top: .1rem;
                padding-bottom: .1rem;
            }
    
            img {
                width: 1.16rem;

                @include d1440 {
                    width: .7rem;
                }
            }
        }
    }
}

body.no-border .header {
    border-bottom: none;
}