@import "../../assets/mixins.scss";

.item-list {

    .container {
        flex-direction: column;

        .title {

            * {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-weight: 600;
                font-size: .32rem;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                    
                @include d1440 {
                    font-size: .28rem;
                }
    
                @include d1280 {
                    font-size: .24rem;
                }
            }
        }
        
        .items {
            position: relative;
            width: 100%;
            border: 1px solid #BBBBBB;

            @include m {
                border-left: none;
                border-right: none;
                width: auto;
                margin-left: -.2rem;
                margin-right: -.2rem;
            }

            .swiper-slide {
                height: auto;

                &:not(.swiper-slide-active) {
                    border-left: 1px solid #BBBBBB;
                }

                @include m {
                    border-left: 1px solid #BBBBBB;
                }

                > div {
                    height: 100%;
                }
            }

            .navigation {
                display: flex;
                position: absolute;
                width: .6rem;
                height: .6rem;
                top:50%;
                margin-top: -.3rem;
                justify-content: center;
                align-items: center;
                border: 1px solid #BBBBBB;
                border-radius: 50%;
                cursor: pointer;
            
                @include d1440 {
                    width: .5rem;
                    height: .5rem;
                    margin-top: -.25rem;
                }

                @include d1280 {
                    width: .4rem;
                    height: .4rem;
                    margin-top: -.2rem;
                }

                @include m {
                    display: none;
                }

                &.left {
                    left: -.9rem;
                    
                    @include d1440 {
                        left: -.8rem;
                    }

                    @include d1280 {
                        left: -.5rem
                    }
                }

                &.right {
                    right: -.9rem;
                    
                    @include d1440 {
                        right: -.8rem;
                    }

                    @include d1280 {
                        right: -.5rem
                    }
                }

                img {
                    width: .23rem;

                    @include d1440 {
                        width: .18rem;
                    }

                    @include d1280 {
                        width: .14rem;
                    }
                }
            }

            .item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex: 1;
                height: 100%;

                .content {
                    padding: .2rem;
                    padding-bottom: .4rem;

                    .image {
                        width: 100%;
                    }

                    .image + * {
                        margin-top: .24rem;
                    }

                    .title {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .18rem;
                        font-weight: 700;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
                            
                        @include d1440 {
                            font-size: .16rem;
                        }
            
                        @include d1280 {
                            font-size: .14rem;
                        }
                    }

                    .text {
                        font-size: .14rem;
                        font-weight: 500;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
            
                        @include d1280 {
                            font-size: .12rem;
                        }
                    }
                }

                .instructor {
                    border-top: 1px solid #BBBBBB;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .profile {
                        width: .6rem;
                        height: .6rem;

                        @include d1440 {
                            width: .5rem;
                            height: .5rem;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .name {
                        flex: 1;
                        padding-left: .1rem;
                        padding-right: .1rem;
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .14rem;
                        font-weight: 500;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        @include d1280 {
                            font-size: .12rem;
                        }
                    }

                    .link {
                        padding-left: .3rem;
                        padding-right: .3rem;
                        height: .6rem;
                        background-color: #404040;

                        @include d1440 {
                            padding-left: .2rem;
                            padding-right: .2rem;
                            height: .5rem;
                        }

                        span {

                            * {
                                font-size: .16rem;
                                font-weight: 600;
                                color: #FFFFFF;
                                letter-spacing: .08em;
                                line-height: 1.5em;
                                text-decoration: none;
    
                                @include d1440 {
                                    font-size: .14rem;
                                }
    
                                @include d1280 {
                                    font-size: .12rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        > * + * {
            margin-top: .6rem;

            @include d1440 {
                margin-top: .5rem;
            }

            @include d1280 {
                margin-top: .4rem;
            }

            @include m {
                margin-top: .24rem;
            }
        }
    }
}