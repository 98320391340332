@import "../../assets/mixins.scss";

.quotation {
    background-color: #214F26;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {

            * {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .32rem;
                font-weight: 500;
                font-style: italic;
                color: #FFFCB2;
                letter-spacing: .08em;
                line-height: 1.5em;
                text-align: center;
                
                @include d1440 {
                    font-size: .28rem;
                }

                @include d1280 {
                    font-size: .24rem;
                }
            }
        }

        .name {

            * {
                font-size: .18rem;
                font-weight: 600;
                color: #FFFCB2;
                letter-spacing: .08em;
                line-height: 1.5em;
                text-align: center;
    
                @include d1440 {
                    font-size: .16rem;
                }
    
                @include d1280 {
                    font-size: .14rem;
                }
            }
        }

        * + .name {
            margin-top: .24rem;
        }
    }
}