@import "../../assets/mixins.scss";

section.half-image-text {
    position: relative;

    @include m {
        padding-top: 0;
        flex-direction: column;
    }

    .background-image-container {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        top: 0;
    
        @include m {
            position: relative;
            width: 100%;
            height: auto;
            right: auto;
            top: auto;
        }

        .background-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
    
            @include m {
                position: relative;
                width: 100%;
                padding-top: 75%;
                right: auto;
                top: auto;
            }
            
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .container {

        .content {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding: .6rem 1.2rem .6rem 0;

            @include d1280 {
                padding: .3rem .6rem .3rem 0;
            }

            @include m {
                padding: 0.3rem 0;
                width: 100%;
            }
                    
            .subtitle {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .2rem;
                font-weight: 600;
                font-style: italic;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
    
                @include d1440 {
                    font-size: .16rem;
                }
    
                @include d1280 {
                    font-size: .14rem;
                }
            }
    
            .title {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .32rem;
                font-weight: 700;
                font-style: italic;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                
                @include d1440 {
                    font-size: .28rem;
                }
    
                @include d1280 {
                    font-size: .24rem;
                }
            }
            
            .text {
                font-size: .18rem;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                
                @include d1440 {
                    font-size: .16rem;
                }
    
                @include d1280 {
                    font-size: .14rem;
                }
            }
    
            > * + * {
                margin-top: .24rem;

                @include d1440 {
                    margin-top: .12rem;
                }
            }
        }
    }

    &.dark {

        .container {

            .content {

                .subtitle {
                    color: #FFFFFF;
                }

                .title {
                    color: #FFFFFF;
                }

                .text {
                    color: #FFFFFF;
                }
            }
        }
    }

    &.image-left {

        .background-image-container {
            left: 0;
            right: auto;

            @include m {
                left: auto;
            }
        }

        .container {
            justify-content: flex-end;

            .content {
                padding: .6rem 0 .6rem 1.2rem;

                @include d1280 {
                    padding: .3rem 0 .3rem .6rem;
                }

                @include m {
                    padding: 0.3rem 0;
                }
            }
        }
    }
}