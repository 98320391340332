@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,500;0,600;0,700;1,500;1,600;1,700&family=Darker+Grotesque:wght@400;500;600;700&display=swap');

@import "mixins.scss";

@include m {
    .desktop-only {
        display: none !important;
    }
}

@include d {
    .mobile-only {
        display: none !important;
    }
}

html {
    font-family: 'Darker Grotesque', Arial, Helvetica, sans-serif !important;
}

body {
    background-color: #FFFBEE !important;

    &.yellow {
        background-color: #FEF3E2 !important;
    }

    &.grey {
        background-color: #F5F5F5 !important;
    }
}

section {
    display: flex;
    justify-content: center;
    padding-top: .7rem;
    padding-bottom: .7rem;

    @include d1440 {
        padding-top: .6rem;
        padding-bottom: .6rem;
    }

    @include d1280 {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}

.container {
    display: flex;
    width: 100%;
    max-width: 16.4rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;

    @include d1280 {
        padding-left: .6rem;
        padding-right: .6rem;
    }

    @include m {
        padding-left: .2rem;
        padding-right: .2rem;
    }
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;

    span {

        * {
            font-size: .18rem;
            font-weight: 600;
            color: #404040;
            text-decoration: underline;
            
            @include  d1440 {
                font-size: .16rem;
            }

            @include  d1280 {
                font-size: .14rem;
            }
        }
    }

    img {
        width: .16rem;

        @include  d1440 {
            width: .14rem;
        }

        @include  d1280 {
            width: .12rem;
        }
    }

    * + img {
        margin-left: .12rem;
    }
}

section.loading {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-color: #FFFBEE;
    transform: translateY(-100vh);
	transition: transform 1s ease-in-out;
    z-index: 99;

    .who-are-you {    
        position: absolute;
        width: 2.7rem;
        height: 10%;
        left: 50%;
        bottom: 5%;
        margin-left: -1.35rem;
        text-align: center;

        @include m {
            width: 2rem;
            margin-left: -1rem;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }
    }
    
    .logo {
        position: absolute;
        width: 5rem;
        height: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        text-align: center;

        @include m {
            width: 3rem;
        }
        
        svg {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }

        &.active {
            svg {
                .logo-icon {
                    transition: transform 3s ease-in-out;
                    transform: translateX(45px);
                }
            }
        }
    }
}

html.loading {

    section.loading {
        transform: none;
    }
}