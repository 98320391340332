@import "../../assets/mixins.scss";

.ticket-list {

    .container {
        flex-direction: column;

        .top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            @include m {
                flex-direction: column;
            }
            
            .title {
                
                * {
                    font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                    font-weight: 600;
                    font-size: .32rem;
                    color: #404040;
                    letter-spacing: .08em;
                    line-height: 1.5em;
                        
                    @include d1440 {
                        font-size: .28rem;
                    }
        
                    @include d1280 {
                        font-size: .24rem;
                    }
                }
            }

            .all-package-price {
                display: flex;
                height: .6rem;
                justify-content: flex-end;
                align-items: center;
                background-color: #E52B2B;

                @include d1440 {
                    height: .5rem;
                }

                @include m {
                    margin-top: .24rem;
                    justify-content: center;
                }

                span {
                    padding-right: .18rem;
                    
                    * {
                        font-weight: 600;
                        font-size: .18rem;
                        color: #FFFFFF;
                        letter-spacing: .08em;
                        line-height: 1.2em;
    
                        @include d1440 {
                            font-size: .16rem;
                        }
    
                        @include d1280 {
                            font-size: .14rem;
                        }
                    }

                    &:first-child {
                        padding-left: .18rem;

                        * {
                            font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        }
                    }

                    @include d1440 {
                        padding-right: .16rem;
                    }

                    @include d1280 {
                        padding-right: .14rem;
                    }
                }


                .icon {
                    padding: 0 .18rem;
                    border-left: 1px solid #FFFFFF;

                    @include d1440 {
                        padding: 0 .16rem;
                    }

                    @include d1280 {
                        padding: 0 .14rem;
                    }

                    img {
                        width: .24rem;

                        @include d1440 {
                            width: .2rem;
                        }

                        @include d1280 {
                            width: .18rem;
                        }
                    }
                }
            }
        }
        
        .items {

            .item {
                padding-top: .24rem;
                padding-bottom: .24rem;
                border-bottom: 1px solid #404040;

                &.passive {
                    opacity: .5;
                }

                @include m {
                    flex-wrap: wrap;
                    padding-top: .24rem;
                    padding-bottom: .24rem;
                }

                .top {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @include m {
                        flex-wrap: wrap;
                    }

                    .date {
                        width: .6rem;
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-weight: 600;
                        font-size: .32rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: .8em;
                        border-right: 1px solid #CCCCCC;
    
                        @include d1440 {
                            font-size: .28rem;
                        }
    
                        @include d1280 {
                            font-size: .24rem;
                        }
    
                        @include m {
                            flex: 100%;
                            border-right: none;
                        }
    
                        br {
    
                            @include m {
                                display: none;
                            }
                        }
    
                        span {
                            font-size: .24rem;
    
                            @include d1440 {
                                font-size: .2rem;
                            }
        
                            @include d1280 {
                                font-size: .18rem;
                            }
                        }
                    }
    
                    .name {
                        flex: 1;
                        padding-right: .84rem;
                        font-weight: 700;
                        font-size: .18rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
    
                        @include d1440 {
                            font-size: .16rem;
                        }
    
                        @include d1280 {
                            font-size: .14rem;
                        }
    
                        @include m {
                            margin-top: .12rem;
                            flex: 50%;
                            padding-right: .1rem;
                        }
                    }

                    * + .name {
                        padding-right: 0;
                        padding-left: .24rem;

                        @include m {
                            padding-left: 0;
                        }
                    }
    
                    .program {
                        flex: 1;
                        padding-left: .24rem;
                        font-weight: 500;
                        font-size: .18rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
    
                        @include d1440 {
                            font-size: .16rem;
                        }
    
                        @include d1280 {
                            font-size: .14rem;
                        }
    
                        @include m {
                            margin-top: .12rem;
                            flex: 50%;
                            padding-left: 0;
                            padding-right: .1rem;
                        }
                    }
    
                    .instructor {
                        flex: 1;
                        padding-left: .12rem;
                        padding-right: .12rem;
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-size: .14rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.2em;
                        text-align: center;
    
                        @include d1440 {
                            font-size: .12rem;
                        }
    
                        @include m {
                            margin-top: .12rem;
                            flex: 50%;
                            padding-left: 0;
                            padding-right: .1rem;
                            text-align: left;
                        }
    
                    }
    
                    .place {
                        flex: 1;
                        padding-left: .12rem;
                        padding-right: .12rem;
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-weight: 500;
                        font-size: .14rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.2em;
                        text-align: center;
    
                        @include d1440 {
                            font-size: .12rem;
                        }
    
                        @include m {
                            margin-top: .12rem;
                            flex: 50%;
                            padding-left: 0;
                            padding-right: .1rem;
                            text-align: left;
                        }
    
                    }
    
                    .price-placeholder {
                        width: 1.8rem;
                    }
    
                    .price {
                        display: flex;
                        width: 1.8rem;
                        height: .6rem;
                        justify-content: flex-end;
                        align-items: center;
                        background-color: #404040;
    
                        @include d1440 {
                            width: 1.6rem;
                            height: .5rem;
                        }
    
                        @include d1280 {
                            width: 1.4rem;
                        }
    
                        @include m {
                            margin-top: .12rem;
                            flex: 100%;
                            justify-content: center;
                        }
    
                        span {
                            padding-right: .18rem;
                            font-weight: 300;
                            font-size: .18rem;
                            color: #FFFFFF;
                            letter-spacing: .08em;
                            line-height: 1.2em;
        
                            @include d1440 {
                                font-size: .16rem;
                                padding-right: .16rem;
                            }
        
                            @include d1280 {
                                font-size: .14rem;
                                padding-right: .14rem;
                            }
                        }
    
    
                        .icon {
                            padding: 0 .18rem;
                            border-left: 1px solid #FFFFFF;
    
                            @include d1440 {
                                padding: 0 .16rem;
                            }
    
                            @include d1280 {
                                padding: 0 .14rem;
                            }
    
                            img {
                                width: .24rem;
    
                                @include d1440 {
                                    width: .2rem;
                                }
    
                                @include d1280 {
                                    width: .18rem;
                                }
                            }
                        }
                    }
                }

                .bottom {

                    .description {
                        margin-top: .12rem;

                        * {
                            font-size: .18rem !important;
                            color: #404040 !important;
                            letter-spacing: .08em !important;
                            line-height: 1.5em !important;
    
                            @include d1440 {
                                font-size: .16rem !important;
                            }
    
                            @include d1280 {
                                font-size: .14rem !important;
                            }
                        }
                    }

                }
            }
        }

        > * + * {
            margin-top: .6rem;

            @include d1440 {
                margin-top: .5rem;
            }

            @include d1280 {
                margin-top: .4rem;
            }

            @include m {
                margin-top: .24rem;
            }
        }
    }
}