@import "../../assets/mixins.scss";

.item-preview {
    background-color: #FFF3DB;

    .container {
        flex-direction: column;

        .title {

            * {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .32rem;
                font-weight: 600;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                
                @include d1440 {
                    font-size: .28rem;
                }
    
                @include d1280 {
                    font-size: .24rem;
                }
            }
        }

        .text {

            * {
                font-size: .18rem;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                
                @include d1440 {
                    font-size: .16rem;
                }
    
                @include d1280 {
                    font-size: .14rem;
                }
            }
        }

        .items {
            display: flex;
            border: 1px solid #BBBBBB;

            @include m {
                flex-direction: column;
            }
            
            .item {
                padding: .16rem;
                padding-bottom: .36rem;

                @include d1440 {
                    padding: .12rem;
                    padding-bottom: .24rem;
                }

                @include m {
                    
                }
                
                & + .item {
                    border-left: 1px solid #BBBBBB;
    
                    @include m {
                        border-left: none;
                        border-top: 1px solid #BBBBBB;
                    }
                }

                .image {
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }

                .title {

                    * {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .18rem;
                        font-weight: 600;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
                        text-align: center;
                
                        @include d1440 {
                            font-size: .16rem;
                        }
            
                        @include d1280 {
                            font-size: .14rem;
                        }
                    }
                }

                * + .title {
                    margin-top: .24rem;

                    @include d1280 {
                        margin-top: .12rem;
                    }
                }
            }
        }

        * + .items {
            margin-top: .6rem;

            @include m {
                margin-top: .3rem;
            }
        }

        .bottom {
            display: flex;
            justify-content: flex-end;
        }

        * + .bottom {
            margin-top: .32rem;
            
            @include d1280 {
                margin-top: .24rem;
            }
        }
    }
}