@import "../../assets/mixins.scss";

section.static {

    .container {

        @include m {
            flex-direction: column;
        }

        .menu {
            width: 3.6rem;
            padding-right: .6rem;
            padding-top: .3rem;
            padding-bottom: .3rem;
            border-right: 1px solid #BBBBBB;

            @include d1440 {
                width: 3rem;
            }

            @include d1280 {
                width: 2.3rem;
                padding-right: .3rem;
            }

            @include m {
                width: 100%;
                padding: 0;
                border-right: 0;
            }

            .items {
                position: sticky;
                top: .3rem;

                @include m {
                    position: relative;
                    top: 0;
                }

                .item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    div {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    span {
                        font-size: .16rem;
                        font-weight: 600;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;

                        @include d1440 {
                            font-size: .14rem;
                        }
            
                        @include d1280 {
                            font-size: .12rem;
                        }
                    }

                    img {
                        width: .24rem;

                        @include d1440{
                            width: .2rem;
                        }

                        @include d1280{
                            width: .16rem;
                        }
                    }
                }

                > * + * {
                    margin-top: .32rem;

                    @include d1440 {
                        margin-top: .28rem;
                    }

                    @include d1280 {
                        margin-top: .24rem;
                    }
                }
            }
        }

        .content {
            flex: 1;
            padding-left: 1.2rem;

            @include d1440 {
                padding-left: .6rem;
            }

            @include d1280 {
                padding-left: .4rem;
            }

            @include m {
                padding-left: 0;
                margin-top: .24rem;
            }

            .title {

                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .32rem;
                font-weight: 600;
                font-style: italic;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;

                @include d1440 {
                    font-size: .28rem;
                }

                @include d1280 {
                    font-size: .24rem;
                }
            }

            .image {
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 75%;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    object-fit: cover;
                }
            }

            .video {
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 75%;
                overflow: hidden;
                background-color: black;

                video {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    object-fit: contain;
                }
            }

            .text {

                * {
                    font-size: .18rem;
                    color: #404040;
                    letter-spacing: .08em;
                    line-height: 1.5em;
                    font-weight: 500;
        
                    @include d1440 {
                        font-size: .16rem;
                    }
        
                    @include d1280 {
                        margin-top: .12rem;
                        font-size: .14rem;
                    }
                }
                
                @include d1280 {
                    margin-top: .12rem;
                }

                p + p {
                    margin-top: .2rem;
    
                    @include d1280 {
                        margin-top: .12rem;
                    }
                }

                a {
                    font-weight: 700;
                    color: inherit;
                }

                b, strong {
                    font-weight: 900;
                };
            }

            * + * {
                margin-top: .24rem;
            }
        }
    }
}