@import "../../assets/mixins.scss";

.full-text {

    .container {
        flex-direction: column;
        text-align: center;
    
        .subtitle {

            * {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .2rem;
                font-weight: 600;
                font-style: italic;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
    
                @include d1440 {
                    font-size: .16rem;
                }
    
                @include d1280 {
                    font-size: .14rem;
                }
            }
        }

        .title {

            * {
                font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                font-size: .32rem;
                font-weight: 700;
                font-style: italic;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                
                @include d1440 {
                    font-size: .28rem;
                }
    
                @include d1280 {
                    font-size: .24rem;
                }
            }
        }
        
        .text {

            * {
                font-size: .18rem;
                color: #404040;
                letter-spacing: .08em;
                line-height: 1.5em;
                
                @include d1440 {
                    font-size: .16rem;
                }
    
                @include d1280 {
                    font-size: .14rem;
                }
            }
        }

        > * + * {
            margin-top: .24rem;

            @include d1440 {
                margin-top: .12rem;
            }
        }
    }
}