@import "../../assets/mixins.scss";

.alert {

    .content {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 1rem);
        max-width: 12rem;
        padding: .6rem 1.2rem;
        background-color: #F4F1ED;
        border-radius: .2rem;

        @include d1440 {
            padding: .6rem;
        }

        @include d1280 {
            padding: .4rem;
        }

        @include m {
            width: calc(100% - .4rem);
            padding: .3rem;
        }

        .close {
            position: absolute;
            right: .3rem;
            top: .3rem;
            width: .4rem;
            cursor: pointer;

            @include d1280 {
                right: .2rem;
                top: .2rem;
                width: .3rem;
            }

            img {
                width: 100%;
            }
        }

        .title {
            font-family: 'Alegreya', Arial, Helvetica, sans-serif;
            font-weight: 600;
            font-size: .32rem;
            color: #404040;
            letter-spacing: .08em;
            line-height: 1.5em;
                
            @include d1440 {
                margin-top: .24rem;
                font-size: .28rem;
            }

            @include d1280 {
                font-size: .24rem;
            }
        }
        
        .text {
            font-size: .18rem;
            color: #404040;
            letter-spacing: .08em;
            line-height: 1.5em;
                
            @include d1440 {
                font-size: .16rem;
            }
                
            @include d1280 {
                font-size: .14rem;
            }

            a {
                text-decoration: underline;
                font-weight: 600;
                color: #404040;
            }
        }

        * + .text {
            margin-top: .24rem;
        }
    }
}