@import "../../assets/mixins.scss";

footer {
    display: flex;
    justify-content: center;
    padding-top: .24rem;
    padding-bottom: .24rem;
    border-top: 1px solid #DDDDDD;

    .container {
        align-items: center;

        @include m {
            flex-direction: column;
        }

        .logo {
    
            img {
                width: 3rem;

                @include d1440 {
                    width: 2rem;
                }

                @include d1280 {
                    width: 1.8rem;
                }
            }
        }

        .menu {
            flex: 1;
            padding-left: 1.2rem;
            
            @include d1440 {
                padding-left: .6rem;
            }

            @include d1280 {
                padding-left: .3rem;
            }

            @include m {
                padding-left: 0;
            }

            .columns {
                display: flex;
                flex: 1;
                flex-direction: row;

                @include m {
                    flex-wrap: wrap;
                }

                .column {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    padding-right: .3rem;

                    @include d1440 {
                        padding-right: .2rem;
                    }

                    @include d1280 {
                        padding-right: .1rem;
                    }

                    @include m {
                        flex: 50%;
                        margin-top: .24rem;
                    }
    
                    .title {

                        * {
                            font-weight: 700;
                            font-size: .14rem;
                            color: #404040;
                            letter-spacing: .08em;
                            line-height: 1.5em;
                        }
                    }
    
                    .item {
                        font-weight: 500;
                        font-size: .14rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;

                        a {
                            font-weight: 700;
                            color: inherit;
                        }
                    }
    
                    * + * {
                        margin-top: .24rem;
            
                        @include d1440 {
                            margin-top: .12rem;
                        }
                    }
                }

            }

            .bottom {
                display: flex;
                justify-content: space-between;
                margin-top: .48rem;

                @include d1280 {
                    margin-top: .24rem;
                    flex-direction: column;
                }

                img {
                    width: 3.3rem;
            
                    @include d1440 {
                        width: 2.6rem;
                    }
                }

                span {

                    * {
                        font-weight: 500;
                        font-size: .12rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
    
                        @include d1280 {
                            margin-top: .24rem;
                        }
    
    
                        @include d1440 {
                            font-size: .1rem;
                        }
                    }
                }
            }
        }
    }
}