@import "../../assets/mixins.scss";

.promotion {
    position: relative;

    .background-image {
        position: absolute;
        width: 50%;
        height: 100%;
        right: 0;
        top: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
        img {
            width: 100%;
            height: 100%;
        }

        @include m {
            width: 100%;
            height: 3rem;
        }
    }

    .container {

        padding-top: 1rem;
        padding-bottom: 1rem;

        @include m {
            padding-top: 3rem;
            padding-bottom: 0;
        }

        .items {
            position: relative;
            width: 50%;
            padding-right: 1.5rem;

            @include d1440 {
                padding-right: 1.2rem;
            }

            @include d1280 {
                padding-right: .8rem;
            }

            @include m {
                width: 100%;
                padding-right: 0;
            }

            &.full {
                width: 100%;
                padding-right: 0;

                .navigation {
                    &.right {
                        right: -.9rem;
                    
                        @include d1440 {
                            right: -.8rem;
                        }
    
                        @include d1280 {
                            right: -.5rem
                        }
                    }
                }
            }

            .navigation {
                display: flex;
                position: absolute;
                width: .6rem;
                height: .6rem;
                top:50%;
                margin-top: -.3rem;
                justify-content: center;
                align-items: center;
                border: 1px solid #BBBBBB;
                border-radius: 50%;
                cursor: pointer;
            
                @include d1440 {
                    width: .5rem;
                    height: .5rem;
                    margin-top: -.25rem;
                }
            
                @include d1440 {
                    width: .5rem;
                    height: .5rem;
                    margin-top: -.25rem;
                }

                @include d1280 {
                    width: .4rem;
                    height: .4rem;
                    margin-top: -.2rem;
                }

                @include m {
                    display:  none;
                }

                &.left {
                    left: -.9rem;
                    
                    @include d1440 {
                        left: -.8rem;
                    }

                    @include d1280 {
                        left: -.5rem
                    }
                }

                &.right {
                    right: .6rem;
                    
                    @include d1440 {
                        right: .5rem;
                    }

                    @include d1280 {
                        right: .3rem
                    }
                }

                img {
                    width: .23rem;

                    @include d1440 {
                        width: .21rem;
                    }

                    @include d1280 {
                        width: .18rem;
                    }
                }
            }

            .item {

                .section-title {

                    * {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .24rem;
                        font-weight: 600;
                        font-style: italic;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
    
                        @include d1440 {
                            font-size: .2rem;
                        }
    
                        @include d1280 {
                            font-size: .18rem;
                        }
                    }

                }
                    
                .subtitle {

                    * {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .2rem;
                        font-weight: 600;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
    
                        @include d1440 {
                            font-size: .16rem;
                        }
    
                        @include d1280 {
                            font-size: .14rem;
                        }
                    }
                }

                .title {
                    
                    * {
                        font-family: 'Alegreya', Arial, Helvetica, sans-serif;
                        font-size: .32rem;
                        font-weight: 700;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
                        
                        @include d1440 {
                            font-size: .28rem;
                        }
    
                        @include d1280 {
                            font-size: .24rem;
                        }
                    }
                }
                
                .text {
                    
                    * {
                        font-size: .18rem;
                        color: #404040;
                        letter-spacing: .08em;
                        line-height: 1.5em;
                        
                        @include d1440 {
                            font-size: .16rem;
                        }
    
                        @include d1280 {
                            font-size: .14rem;
                        }
                    }
                }

                .bottom {
                    display: flex;
                    justify-content: flex-end;
                }
        
                > * + * {
                    margin-top: .24rem;

                    @include d1440 {
                        margin-top: .12rem;
                    }
                }
            }
        }
    }
}